import React, { useEffect } from "react";
import { hospitalSteps, steps } from "../utils/mockData";
import { useAppState } from "../contexts/appContext";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { IntroForm } from "../components/forms/IntroForm";
import { useLocation, useNavigate } from "react-router-dom";
import { FormContainer } from "../components/FormContainer";
import { FormContextProvider } from "../contexts/formContext";
import { generateFullSchema } from "../utils/validationSchemas";
import { SelectDoctor } from "../components/forms/SelectDoctor";
import { NewConsultation } from "../components/forms/NewConsultation";
import { SelectHospital } from "../components/forms/SelectHospital";
import { SelectHospitalDoctor } from "../components/forms/SelectHospitalDoctor";
import { convertExternalViaToCreatedThrough } from "../utils/funcs";

const HospitalForms = {
  [hospitalSteps.INTRO_FORM]: <IntroForm />,
  [hospitalSteps.SELECT_HOSPITAL]: <SelectHospital />,
  [hospitalSteps.SELECT_HOSPITAL_DOCTOR]: <SelectHospitalDoctor />,
  [hospitalSteps.SELECT_DOCTOR]: <SelectDoctor />,
  [hospitalSteps.CREATE]: <NewConsultation />,
};

const forms = {
  [steps.INTRO_FORM]: <IntroForm />,
  [steps.SELECT_DOCTOR]: <SelectDoctor />,
  [steps.CREATE]: <NewConsultation />,
};

const getForms = (isHospitalConsultation) =>
  isHospitalConsultation ? HospitalForms : forms;

const CreateConsultation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const appState = useAppState();
  const via = convertExternalViaToCreatedThrough(location?.state?.via);
  const isHospitalConsultation = appState?.isHospitalConsultation;
  const hasPharmacyFeature = appState?.providerFeatures?.hasPharmacyFeature;
  const hasFollowUpFeature = appState?.providerFeatures?.hasFollowUpFeature;

  const savedFormData = sessionStorage.getItem("RHF_Data");
  const parsedFormData = JSON.parse(savedFormData) || {};
  const schema = generateFullSchema(hasPharmacyFeature)(hasFollowUpFeature)(
    isHospitalConsultation
  );
  const schemaKeys = Object.keys(schema.fields);
  const filteredDefaultValues = Object.keys(parsedFormData)
    .filter((key) => schemaKeys.includes(key))
    .reduce((obj, key) => {
      obj[key] = parsedFormData[key];
      return obj;
    }, {});
  const type = isHospitalConsultation ? "scheduled" : "instant";

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: { ...filteredDefaultValues, type, createdThrough: via },
    mode: "onChange",
    shouldFocusError: true,
  });

  // Watch all form values to update sessionStorage on change
  const formValues = methods.watch();
  useEffect(() => {
    sessionStorage.setItem("RHF_Data", JSON.stringify(formValues));
  }, [formValues]);

  useEffect(() => {
    if (!appState?.partnerInfo) {
      const partnerSubDomain = location?.pathname?.split("/")[1];
      navigate(`/${partnerSubDomain}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState?.partnerInfo]);

  return (
    <FormProvider {...methods}>
      <FormContextProvider>
        <FormContainer>
          {getForms(isHospitalConsultation)[appState.step]}
        </FormContainer>
      </FormContextProvider>
    </FormProvider>
  );
};

export default CreateConsultation;
